import React, { useContext } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { submitLanding } from "../redux/features/customer-requests/contactusSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, ASSETS, SITE_KEY } from "../config/Constants";
import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getLandingBanners } from "../redux/features/cms/bannerSlice";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLanguage } from "./LanguageContext";

export default function Landing_page(props) {
  const [banners, setBanners] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const language = pathSegments[1];
  const { selectedLanguage, handleLanguageChange } = useLanguage();

  const campaign = pathSegments[2];
  const source = pathSegments[3];

  const dispatch = useDispatch();

  useEffect(() => {
    handleLanguageChange(language);

    const fetchData = async () => {
      try {
        const bannersData = await dispatch(getLandingBanners());
        console.log("Banners Data:", bannersData.payload);
        setBanners(bannersData.payload);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  console.log(banners);
  const empty_values = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    source,
    campaign,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const recaptchaRef = useRef(null);

  const onsubmitContact = async (e) => {
    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    if (captchaToken) {
      await axios
        .get(`${API_URL}/customer/verifyCaptcha/${captchaToken}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.data.success && res.data.message.success) {
            setIsLoading(true);
            dispatch(submitLanding(formData)).then((res) => {
              if (res.payload.success) {
                toast.success(res.payload.message);
                setFormData(empty_values);
              }
              setIsLoading(false);
            });
          }
        })
        .catch((error) => {
          toast.error("Invalid catch block");
          setIsLoading(false);
        });
    } else {
      toast.error("Invalid Captcha");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Bahwan automobiles & Trading LLC</title>
        <meta
          name="title"
          content="Explore a variety of automotive services at Bahwan Automobiles & Trading - Expert solutions for your vehicle."
        />
        <link
          rel="canonical"
          href="https://bat-oman.com/landing-page/en/website/website"
        />
        <meta
          name="description"
          content="Explore a variety of automotive services at Bahwan Automobiles & Trading - Expert solutions for your vehicle."
        />
      </Helmet>

      <section className="contact-section-two pb-0">
        <div className="auto-container">
          <div className="inner-container mx-auto">
            

            <div className="sec-title text-center mb-3 mt-5">

            {banners && banners.length > 0 && selectedLanguage === "en" ? (
              <img
                src={`${ASSETS}landing_banners/${banners[0].image}`}
                alt="Values"
                className="img-fluid"
                style={{maxHeight:"800px"}}
              />
            ) : banners && banners.length > 1 ? (
              <img
                src={`${ASSETS}landing_banners/${banners[1].image}`}
                alt="Values"
                className="img-fluid"
                style={{maxHeight:"800px"}}
              />
            ) : (
              <div className="text-center">No banner available</div>
            )}


              <h2 className="mt-5">{selectedLanguage == "en" ? "Ramdhan Offer" : "عــــرض رمــــضان"}</h2>

              {/* <div className="text mb-2">
                {selectedLanguage == "en"
                  ? "Your Dedicated Personal Car Service Advisor is Available to Assist You."
                  : " Your Dedicated Personal Car Service Advisor is Available to Assist You."}
                <br />
              </div> */}
            </div>

            <div className="contact-form-two mt-4 px-3 pt-3 col-md-8 mx-auto mb-4">
              <form>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input
                      type="text"
                      name="name"
                      id="id"
                      value={formData.name}
                      placeholder={selectedLanguage == "en" ? "Name" : "الإسم"}
                      onChange={onChange}
                      required=""
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={onChange}
                      placeholder={
                        selectedLanguage == "en" ? "phone" : "الهاتف"
                      }
                      required=""
                      data-parsley-minlength="9"
                      data-parsley-minlength-message="Mobile number should be at least 9 to 12 long"
                      data-parsley-type="digits"
                      data-parsley-type-message="Mobile number should be only numbers"
                      maxLength="12"
                      data-parsley-pattern="^[\d\+\-\.\(\)\/\s]*$"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input
                      type="email"
                      name="email"
                      onChange={onChange}
                      value={formData.email}
                      placeholder={
                        selectedLanguage == "en" ? "Email" : "البريد الإلكتروني"
                      }
                      required=""
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                    <input
                      type="text"
                      name="message"
                      id="message"
                      value={formData.message}
                      placeholder={
                        selectedLanguage == "en" ? "Message" : "رسالة"
                      }
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    {isLoading ? (
                      <div className="text-center">
                        <img
                          className="mx-auto"
                          src="/assets/front/loader/loader.gif"
                          style={{ Height: "50px" }}
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="text-center mx-auto">
                          <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} />

                          <button
                            className="theme-btn btn-style-two text-center mt-4 submitFormButton"
                            type="button"
                            onClick={() => onsubmitContact()}
                            name="submit-form"
                          >
                            <span className="btn-title">
                              {selectedLanguage == "en"
                                ? "Send Message"
                                : "أرسل رسالة"}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
