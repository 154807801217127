import React, { useEffect} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getServiceData } from "../redux/features/cms/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLanguage } from "./LanguageContext";
import { ASSETS } from "../config/Constants";
import parse from "html-react-parser";

export default function Services() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceData());
  }, []);

  const services = JSON.parse(
    JSON.stringify(useSelector((state) => state.services))
  );
  const location = useLocation();
  const { selectedLanguage } = useLanguage();

  const homeData = useSelector((state) => state.staticData.data) || [];

  const seoData = useSelector((state) => state.metaData.data) || [];


  const getNameByTargetName = (targetName) => {
    const targetObject = homeData.find((item) => item.name === targetName);
    if (selectedLanguage == "en") {
      return targetObject ? targetObject.value : null;
    } else {
      return targetObject ? targetObject.value_ar : null;
    }
  };

  return (
    <>
    {location.pathname==="/services" && 
    
    <Helmet>
    {seoData&&seoData[0]&&parse(seoData[3].meta_data)}
    </Helmet>
    
    }
      <section className="pricing-section-two ">
        <div className="auto-container pt-4">
          <div className="sec-title text-center">
            <h2>{getNameByTargetName("OUR_SERVICES_TITLE")}</h2>
            <div className="text">
              {getNameByTargetName("OUR_SERVICE_CONTENT")}
            </div>
          </div>
          <div className="row main-services">
            <div className="col-12">
              <div className="row justify-content-center">
                {services && services.data
                  ? services.data.map((element, key) => {
                      return (
                        <div
                          className="col-lg-3 col-md-4 col-sm-4 col-6 mt-4"
                          key={key}
                        >
                          <div className="each_service_package">
                            <div className="icon_container">
                              <a href={element.link}>
                                <img
                                  className="img-fluid d-block mx-auto"
                                  title="Car Oil Change"
                                  alt="Car Oil Change"
                                  src={ASSETS + "ourcarservices/" + element.icon}
                                />
                              </a>
                            </div>
                            <div className="text_container">
                              <h3>
                                <a href={"service/" + element.slug}>
                                  {selectedLanguage == "en"
                                    ? element.name
                                    : element.name_ar}
                                </a>
                              </h3>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Outlet />
    </>
  );
}
