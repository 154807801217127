import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import Homepage from "./pages/Homepage";
import About_us from "./pages/About_us";
import Blogs from "./pages/Blogs";
import Contact_us from "./pages/Contact-us";
import Gallery from "./pages/Galleries";
import Get_quote from "./pages/Get_quote";
import Packages from "./pages/Packages";
import Services from "./pages/Services";
import Dynamic_page from "./pages/Dynamic_page";
import Book_service from "./pages/Book_service";
import ScrollToTop from "./components/ScrollToTop";
import Repair_car_quote from "./pages/Repair_car_quote";
import Book_addon from "./pages/Book_addon";
// services
import Service_view from "./pages/service-pages/Service_view";

import Myaccount from "./pages/Myaccount";
import Login from "./pages/Login";
import Reset_password from "./pages/Reset_password";
import PrivateRoute from "./components/ProtectedRoutes";
import LoginPrivateRoute from "./components/LoginPrivateRoute";
import Booking_view from "./pages/Booking_view";
import Booking_list from "./pages/Booking_list";
import { checkAuth } from "./redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData, getStaticData } from "./redux/features/cms/staticDataSlice";
import Loader from "./components/loader/Loader";
import { Helmet } from "react-helmet";
import Payment_getway from "./pages/Payment_getway";
import Offers from "./pages/Offers";
import { LanguageProvider } from "./pages/LanguageContext";
import Landing_page from "./pages/Landing_page";

function App() {
  const disptach = useDispatch();
  const [meta_data, setMedata_data]=useState([]);
  useEffect(() => {
    disptach(getStaticData());
    disptach(getMetaData()).then((res)=>{
      setMedata_data(res.payload)
    });
    disptach(checkAuth())
      .then((res) => {})
      .catch((error) => {
        window.location.reload();
      });
  }, []);


  const static_data = JSON.parse(
    JSON.stringify(useSelector((state) => state.staticData))
  );
  if (static_data.loading == "PENDING") {
    return <Loader />;
  }



  
  return (
    <LanguageProvider>

    <div className="App">

      <Router>
        <ScrollToTop />
        <Layout {...static_data.data}>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="about" element={<About_us />} />
            <Route exact path="blog" element={<Blogs />} />
            <Route exact path="contact-us" element={<Contact_us />} />
            <Route exact path="gallery" element={<Gallery />} />
            <Route exact path="get-quote" element={<Get_quote />} />
            <Route exact path="packages" element={<Packages />} />
            <Route exact path="offers" element={<Offers />} />
            <Route exact path="services" element={<Services />} />
            <Route exact path="service/:id" element={<Service_view />} />
            <Route exact path="page/:slug" element={<Dynamic_page />} />
            <Route exact path="landing-page/:language/:campaign/:source" element={<Landing_page />} />
           
            <Route
              exact
              path="repair-car-quote"
              element={<Repair_car_quote />}
            />

            <Route path="*" element={<Navigate to="/" />} />

            {/* myaccoutn routes */}
            <Route path="/profile">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Myaccount />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/payment-getway">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Payment_getway />
                  </PrivateRoute>
                }
              />
            </Route>


            <Route path="/booking-service/:id">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Book_service />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/book_addon">
            <Route
                path=""
                element={
                    <Book_addon />
                }
              />
            </Route>

            <Route path="/booking-list">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Booking_list />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/booking-view/:id">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Booking_view />
                  </PrivateRoute>
                }
              />
            </Route>
            

            {/* if user logged in  redirect to profile */}
            <Route path="/auth">
              <Route
                path=""
                element={
                  <LoginPrivateRoute>
                    <Login />
                  </LoginPrivateRoute>
                }
              />
            </Route>

            <Route path="/reset-password">
              <Route
                path=""
                element={
                  <LoginPrivateRoute>
                    <Reset_password />
                  </LoginPrivateRoute>
                }
              />
            </Route>
            {/* end  */}
          </Routes>
        </Layout>
      </Router>
    </div>
    </LanguageProvider>

  );
}

export default App;
