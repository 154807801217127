import React from "react";
import { Helmet } from "react-helmet";
import { useLanguage } from "./LanguageContext";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

export default function About_us() {
  const { selectedLanguage } = useLanguage();

  const getNameByTargetName = (targetName) => {
    const targetObject = homeData.find((item) => item.name === targetName);
    if (selectedLanguage == "en") {
      return targetObject ? targetObject.value : null;
    } else {
      return targetObject ? targetObject.value_ar : null;
    }
  };

  const homeData = useSelector((state) => state.staticData.data) || [];

  
  const seoData = JSON.parse(
    JSON.stringify(useSelector((state) => state.metaData.data))
  );
 


  const StringToArray = (inputString) => {
    const targetObject = homeData.find((item) => item.name === inputString);
    if (!targetObject) {
      return <div>No data found</div>;
    }
    const stringValue =
      selectedLanguage === "en" ? targetObject.value : targetObject.value_ar;
    if (!stringValue || !stringValue.includes(",")) {
      return <div></div>;
    }
    const stringArray = stringValue.split(",");
    return (
      <ul>
        {stringArray.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  

  return (
    <>
      <Helmet>
      {seoData&&seoData[0]&&parse(seoData[4].meta_data)}
    </Helmet>
      <section className="pricing-section-two">
        <div className="auto-container pt-4">
          <div className="banner-image-about">
            <img src="assets/front/uploads/about/banner.png" />
          </div>
          <div className="sec-title mb-0 mt-5">
            <h2>{getNameByTargetName("ABOUT_US_P_TITLE")}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="auto-container">
          <div className="sec-title text-left">
            <p className="text">{getNameByTargetName("ABOUT_US_P_CONTENT")}</p>
          </div>
        </div>
      </section>
      <section>
        <div className="slider_section_description mb-5">
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="content_container_about_us">
                  <div className="row">
                    <div className="col-md-6  order-2 d-block my-auto">
                      <div className="row">
                        <div className="col">
                          <div className="image_wrapper">
                            <img
                              className="img-fluid d-block mx-auto"
                              src="assets/front/uploads/about/one.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-block my-auto">
                      <div className="main_content">
                        <h3 className="big-text">
                          {getNameByTargetName("ABOUT_US_P_TITLE_ONE")}
                        </h3>
                        <ul>
                          <li>
                            {getNameByTargetName("ABOUT_US_P_CONTENT_ONE")}
                          </li>
                        </ul>

                        <br />

                        <h3 className="big-text">
                          {getNameByTargetName("ABOUT_US_P_TITLE_TWO")}
                        </h3>
                        <ul>
                          <li>
                            {getNameByTargetName("ABOUT_US_P_CONTENT_TWO")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="content_container_about_us">
                  <div className="row">
                    <div className="col-md-6 d-block my-auto">
                      <div className="main_content">
                        <h3 className="big-text">
                          {getNameByTargetName("ABOUT_US_P_TITLE_THREE")}{" "}
                        </h3>
                        {StringToArray("ABOUT_US_P_CONTENT_THREE")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
