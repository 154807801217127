import React, { useEffect } from "react";
import { seo_data } from "../../SEO";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getServiceData } from "../../redux/features/cms/serviceSlice";
import { useLanguage } from "../LanguageContext";
import { ASSETS } from "../../config/Constants";

export default function Side_panel() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    dispatch(getServiceData());
  }, []);

  const services = JSON.parse(
    JSON.stringify(useSelector((state) => state.services.data))
  );

  return (
    <>
      {/*Sidebar Side*/}
      <div className=" col-lg-4 col-md-4 col-sm-12 p-0 pl-md-3">
        {/* *************************Design Sidebar Old******************************* */}
        <div className="sidebar_main_wrapper">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <span className="card-title bold-text text-dark text-uppercase d-block text-center mb-0">
                    {" "}
                    Popular Packages
                  </span>
                </div>
                <div className="card-body">
                  <div className="packages_wrapper">
                    <div className="each_package_item_sidebar">
                      <div className="image_wrapper">
                        <a
                          href="/packages"
                          package-id="42"
                          package-type="AS"
                          className="takeMeToBooking d-block text-center w-100"
                        >
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/uploads/packages/L3RtcC9waHBVQlhaMHg%3d.svg"
                          />
                        </a>
                      </div>
                      <div className="text-container d-block my-auto">
                        <h3 className="medium-text text-shadow">
                          <a href="/packages" className="takeMeToBooking">
                            Headlamp Polishing
                          </a>
                        </h3>
                      </div>
                    </div>
                    <div className="each_package_item_sidebar">
                      <div className="image_wrapper">
                        <a
                          href="/packages"
                          className="takeMeToBooking d-block text-center w-100"
                        >
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/uploads/packages/L3RtcC9waHBHWUdWYW4%3d.svg"
                          />
                        </a>
                      </div>
                      <div className="text-container d-block my-auto">
                        <h3 className="medium-text text-shadow">
                          <a
                            href="/packages"
                            package-id="46"
                            package-type="AS"
                            className="takeMeToBooking"
                          >
                            Lube Service - 5000 Kms
                          </a>
                        </h3>
                      </div>
                    </div>
                    <div className="each_package_item_sidebar">
                      <div className="image_wrapper">
                        <a
                          href="/packages"
                          package-id="16"
                          package-type="AS"
                          className="takeMeToBooking d-block text-center w-100"
                        >
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/uploads/packages/L3RtcC9waHB6RlFJQnU%3d.svg"
                          />
                        </a>
                      </div>
                      <div className="text-container d-block my-auto">
                        <h3 className="medium-text text-shadow">
                          <a href="/packages" className="takeMeToBooking">
                            Periodic Service - 10000 kms
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12  mt-3">
              <div className="card">
                <div className="card-header">
                  <span className="card-title bold-text text-dark text-uppercase d-block text-center mb-0">
                    {" "}
                    Popular Services
                  </span>
                </div>
                <div className="card-body">
                  <div className="packages_wrapper">
                    {services &&
                      services.slice(0, 6).map((element) => (
                        <div className="each_service_item_sidebar">
                          <div className="image_wrapper">
                            <a
                              className="d-block w-100"
                              href={"/service/" + element.slug}
                            >
                              <picture>
                                <source
                                  src={ASSETS + "services/" + element.icon}

                                  type="image/webp"
                                />
                                <source
                                  src={ASSETS + "services/" + element.icon}

                                  type="image/jpg"
                                />
                                <img
                                  alt="Car AC Repair"
                                  title="car ac repair dubai"
                                  className="img-fluid d-block mx-auto w-100 serviceImage"
                                  src={ASSETS + "services/" + element.icon}

                                />
                              </picture>
                            </a>
                          </div>
                          <div className="text-container d-block my-auto">
                            <h3 className="medium-text text-shadow">
                              <a href={"/service/" + element.slug}>
                                {selectedLanguage == "en"
                                  ? element.name
                                  : element.name_ar}
                              </a>
                            </h3>
                            <a
                              href={"/service/" + element.slug}
                              className="btn btn-light py-1 px-3 small-text btn-white"
                            >
                              <span className="btn-title">Read More</span>
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* *************************Design Sidebar Old ends******************************* */}
      </div>
    </>
  );
}
