import React, { useEffect, useState } from "react";
import Side_panel from "./Side_panel";
import How_its_work from "./How_its_work";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../config/Constants";
import axios from "axios";
import parse from "html-react-parser";
import Loader from "../../components/loader/Loader";
import { useLanguage } from "../LanguageContext";

export default function Periodic_maintenance() {
  let location = useLocation();
  let path = location.pathname;
  const pageSlug = path.substring(path.lastIndexOf("/") + 1);
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(API_URL + "/customer/getSingleService/" + pageSlug)
      .then((response) => {
        setData(response.data);
      });
  }, []);

  const { selectedLanguage } = useLanguage();


  return (
    <>
      <Helmet>
      {data && parse(data.metadata) }
      </Helmet>
      <section className="row">
        <div className="col-12 px-0 breadCrumbWrapper">
          <div className="auto-container">
            <div className="row">
              <div className="col-12 pt-4">
                <div className="row">
                  <div className="col-12 text-left">
                    <h1 className="page_title">{data && data.name}</h1>
                    <p>
                      <span>
                        <a href="/" className="text-white">
                          Home
                        </a>
                      </span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>
                        <a href="/services" className="text-white">
                          Services
                        </a>
                      </span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>{data && data.name}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sidebar-page-container pb-0 pt-1">
        <div className="auto-container">
          <div className="row clearfix">
            {/*Content Side*/}
            {selectedLanguage === 'en' ?
  (data && data.content ? parse(data.content) : parse('<div class="col-lg-8 col-md-8  col-sm-12"><h2 class="text-center">Content not found</h2></div>'))
  :
  (data && data.content_ar ? parse(data.content_ar) : parse('<div class="col-lg-8 col-md-8  col-sm-12"><h2 class="text-center">Content not found</h2></div>'))
}

            {/* Side bar */}
            <Side_panel />
          </div>
        </div>
      </div>
      {/* How its work */}
      <How_its_work />
    </>
  );
}
