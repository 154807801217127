export const seo_data = {
  "home": {
    title: "Home - Bahwan Automobiles & Trading",
    description: "Welcome to Bahwan Automobiles & Trading - Transparent, Trustworthy, and Tailor-made services for all brands.",
    keywords: "automobiles, trading, Transparent, Trustworthy, Tailor-made services",
  },
  "about": {
    title: "About Us - Bahwan Automobiles & Trading",
    description: "Learn about Bahwan Automobiles & Trading - Providing Transparent, Trustworthy, and Tailor-made services since 2019.",
    keywords: "about us, Transparent services, Trustworthy, Tailor-made services",
  },
  "blog": {
    title: "Blog - Bahwan Automobiles & Trading",
    description: "Explore the latest blogs and updates from Bahwan Automobiles & Trading - Your source for automotive insights.",
    keywords: "blog, automotive insights, updates",
  },
  "contactus": {
    title: "Contact Us - Bahwan Automobiles & Trading",
    description: "Get in touch with Bahwan Automobiles & Trading - Your trusted partner for automotive services.",
    keywords: "contact us, automotive services, get in touch",
  },
  "gallery": {
    title: "Gallery - Bahwan Automobiles & Trading",
    description: "Browse through the gallery of Bahwan Automobiles & Trading - Showcasing our facilities and satisfied customers.",
    keywords: "gallery, facilities, satisfied customers",
  },
  "getquote": {
    title: "Get a Quote - Bahwan Automobiles & Trading",
    description: "Request a quote from Bahwan Automobiles & Trading - Transparent pricing for all automotive services.",
    keywords: "get a quote, request a quote, automotive services, transparent pricing",
  },
  "packages": {
    title: "Packages - Bahwan Automobiles & Trading",
    description: "Explore our service packages at Bahwan Automobiles & Trading - Tailor-made solutions for your vehicle.",
    keywords: "packages, service packages, tailor-made solutions, vehicle services",
  },
  "offers": {
    title: "Offers - Bahwan Automobiles & Trading",
    description: "Discover the latest offers and promotions from Bahwan Automobiles & Trading - Save on your automotive services.",
    keywords: "offers, promotions, automotive services, save",
  },
  "privacypolicy": {
    title: "Privacy Policy - Bahwan Automobiles & Trading",
    description: "Read our privacy policy at Bahwan Automobiles & Trading - Your data security is our priority.",
    keywords: "privacy policy, data security",
  },
  "termsandconditions": {
    title: "Terms and Conditions - Bahwan Automobiles & Trading",
    description: "Review the terms and conditions of Bahwan Automobiles & Trading - Clear guidelines for our services.",
    keywords: "terms and conditions, guidelines, automotive services",
  },
  "repaircarquote": {
    title: "Car Repair Quote - Bahwan Automobiles & Trading",
    description: "Get a quote for car repair services from Bahwan Automobiles & Trading - Transparent and competitive pricing.",
    keywords: "car repair quote, automotive services, transparent pricing",
  },
  // Add meta information for other routes similarly
  "services": {
    title: "Services dsflkdjfldjsfjdfsj - Bahwan Automobiles & Trading",
    description: "Explore a variety of automotive services at Bahwan Automobiles & Trading - Expert solutions for your vehicle.",
    keywords: "automotive services, expert solutions, vehicle services",
  },
  "services/periodic-maintenance": {
    title: "Periodic Maintenance - Bahwan Automobiles & Trading",
    description: "Schedule periodic maintenance for your vehicle at Bahwan Automobiles & Trading - Keep your car in top condition.",
    keywords: "periodic maintenance, vehicle services, car maintenance",
  },
  "services/prepurchase-inspection": {
    title: "Prepurchase Inspection - Bahwan Automobiles & Trading",
    description: "Ensure the quality of your purchase with a prepurchase inspection at Bahwan Automobiles & Trading.",
    keywords: "prepurchase inspection, vehicle inspection, quality assurance",
  },
  // Add meta information for other service routes similarly
  "myaccount": {
    title: "My Account - Bahwan Automobiles & Trading",
    description: "Access your personalized account at Bahwan Automobiles & Trading - Manage bookings and preferences.",
    keywords: "my account, bookings, preferences",
  },
  "payment": {
    title: "Payment Gateway - Bahwan Automobiles & Trading",
    description: "Securely process payments through the payment gateway at Bahwan Automobiles & Trading.",
    keywords: "payment gateway, secure payments",
  },

"reset": {
    title: "Reset password - Bahwan Automobiles & Trading",
    description: "reset login password at Bahwan Automobiles & Trading.",
    keywords: "reset, password",
  },

"login": {
    title: "Login - Bahwan Automobiles & Trading",
    description: "Login at Bahwan Automobiles & Trading.",
    keywords: "user login, user",
  },

"booking": {
    title: "Book service - Bahwan Automobiles & Trading",
    description: "Boook services at Bahwan Automobiles & Trading.",
    keywords: "bookings, services",
  },


};
