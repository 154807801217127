import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { API_URL } from '../config/Constants';
import Loader from '../components/loader/Loader';

export default function DynamicPage() {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL+`/customer/single_dynamic_pages/${slug}`);
        setPageData(response.data);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    if (slug) {
      fetchData();
    }
  }, [slug]);

  if (!pageData) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData.meta_title || pageData.title}</title>
        <meta name="description" content={pageData.meta_description || ''} />
        <meta name="keywords" content={pageData.meta_keywords || ''} />
        <meta name="robots" content={pageData.meta_robots || 'index,follow'} />
        <meta name="author" content={pageData.meta_author || ''} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={pageData.og_title || pageData.title} />
        <meta property="og:description" content={pageData.og_description || ''} />
        <meta property="og:image" content={pageData.og_image || ''} />
        <meta property="og:url" content={pageData.og_url || window.location.href} />
        <meta property="og:type" content={pageData.og_type || 'website'} />
        <meta property="og:locale" content={pageData.og_locale || 'en_US'} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content={pageData.twitter_card || 'summary'} />
        <meta name="twitter:title" content={pageData.twitter_title || pageData.title} />
        <meta name="twitter:description" content={pageData.twitter_description || ''} />
        <meta name="twitter:image" content={pageData.twitter_image || ''} />
      </Helmet>

      <section className="pricing-section-two">
        <div className="auto-container">
          <div className="sec-title text-center mb-0">
            <h2>{pageData.title}</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="auto-container dynamic">
          <div className="sec-title text-left mb-1">
            <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
          </div>
        </div>
      </section>
    </>
  );
}
