import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader/Loader";
import { getHomePackage } from "../redux/features/booking-data/homePackagesSlice";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ASSETS, CURRENCY } from "../config/Constants";
import {
  getBanners,
  getCarBrands,
  getClients,
  getParnters,
} from "../redux/features/cms/bannerSlice";
import { Helmet } from "react-helmet";
import { useLanguage } from "./LanguageContext";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import How_its_work from "./service-pages/How_its_work";
import Services from "./Services";

export default function Homepage() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    dispatch(getHomePackage());
    dispatch(getBanners());
    dispatch(getCarBrands());
    dispatch(getClients());
    dispatch(getParnters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBanners());
  }, [selectedLanguage, dispatch]);

  const { loading, data } = useSelector((state) => state.homePackage);
  const banners = useSelector((state) => state.banners);
  const clients = useSelector((state) => state.clients);
  const brands = useSelector((state) => state.brands);
  const partners = useSelector((state) => state.partners);
  const homeData = useSelector((state) => state.staticData.data);
  const seoData = useSelector((state) => state.metaData.data);

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const faqs_pages = [
      "FAQS_S1_TITLE",
      "FAQS_S1_CONTENT",
      "FAQS_S2_TITLE",
      "FAQS_S2_CONTENT",
      "FAQS_S3_TITLE",
      "FAQS_S3_CONTENT",
      "FAQS_S4_TITLE",
      "FAQS_S4_CONTENT",
      "FAQS_S5_TITLE",
      "FAQS_S5_CONTENT",
    ];

    const targetObjects = faqs_pages.map((targetName) => {
      if(homeData)
      return homeData.find((item) => item.name === targetName);
    });

    

    const faqs_result = [];
    for (let i = 0; i < targetObjects.length; i += 2) {
      const titleObject = targetObjects[i];
      const contentObject = targetObjects[i + 1];

      if (titleObject && contentObject) {
        faqs_result.push([titleObject, contentObject]);
      }
    }

    setFaqs(faqs_result);
  }, [homeData]);

  const upperCase = (str) => str.toUpperCase();
  const getNameByTargetName = (targetName) => {
    if (!homeData || homeData.length === 0) {
      return null;
    }
    const targetObject = homeData.find((item) => item.name === targetName);
    return selectedLanguage === "en"
      ? targetObject?.value
      : targetObject?.value_ar;
  };

  const renderPackageItem = (packageDetails, key) => (
    <div className="item japan" key={key}>
      <div className="each_package_container">
        <div className="content_package" id={"package_" + key}>
          <h3 className="bold-text">{packageDetails.name}</h3>
          <div className="price_wrapper d-block mt-4 mb-4 text-center">
            <div className="medium-text">{CURRENCY} &nbsp;</div>
            <div className="biggest-text bold-text primary-color">
              {packageDetails.price}
            </div>
          </div>
          <div className="points_container mt-1">
            <ul>
              {packageDetails.features.split(",").map((subitem, i) => (
                <li className="included abs_content_wrapper" key={i}>
                  <div>{subitem}</div>
                </li>
              ))}
            </ul>
          </div>
          <p className="btn_container pt-4">
            <Link
              to={`/booking-service/${packageDetails.id}`}
              className="theme-btn btn-style-two takeMeToBooking d-block w-100 text-center"
            >
              Book Now &nbsp; <i className="fas fa-arrow-right"></i>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );

  const renderCountryPackages = (singleCountrypackages, singlekey) => (
    <div key={singlekey}>
      <div className="text-center m-1">
        <h5 className="mx-auto">{singleCountrypackages[singlekey].country}</h5>
      </div>
      <div className="row rect-owel package-one">
        <OwlCarousel
          className="owl-theme"
          {...options}
          loop
          smartSpeed={700}
          margin={20}
          rtlClass="owl-rtl"
          rtl={true}
        >
          {singleCountrypackages
            ? singleCountrypackages.map((packageDetails, key) =>
                renderPackageItem(packageDetails, key)
              )
            : null}
        </OwlCarousel>
      </div>
    </div>
  );

  localStorage.setItem("lastLocation", "/packages");

  if (
    loading === "PENDING" ||
    banners.loading === "PENDING" ||
    clients.loading === "PENDING" ||
    partners.loading === "PENDING" ||
    brands.loading === "PENDING"
  ) {
    return <Loader />;
  }

  const options = {
    margin: 30,
    responsiveClass: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <Helmet>
        {seoData && seoData[0] && parse(seoData[0].meta_data)}
      </Helmet>

      {/* Banner Section */}
      <OwlCarousel
        className="owl-theme"
        items={1}
        slideBy={1}
        loop={true}
        rtlClass="owl-rtl"
        rtl={true}
        autoplay={true}
      >
        {banners && banners.data && 
          banners.data
            .filter((banner) => upperCase(selectedLanguage) === upperCase(banner.language))
            .map((banner, key) => (
              <div className="item one" key={key}>
                <div className="af_slider">
                  <a
                    href={banner.link ? banner.link : '#'}
                    package-id="23"
                    package-type="SP"
                    className="takeMeToBooking"
                  >
                    <picture>
                      <source
                        media="(min-width:991px)"
                        srcSet={ASSETS + "banners/" + banner.image}
                      />
                      <source
                        media="(min-width:575px)"
                        srcSet={ASSETS + "banners/" + banner.image}
                      />
                      <img
                        src={ASSETS + "banners/" + banner.image}
                        alt="Values"
                        className="img-fluid"
                      />
                    </picture>
                  </a>
                </div>
              </div>
            ))
        }
      </OwlCarousel>
      {/*End Banner Section */}

      {/* Packages */}
      <div className="mt-sm-5">
        <section>
          <div className="packages_wrapper">
            <div className="auto-container">
              <div className="sec-title text-center mt-3 mb-0">
                <h2>Service Packages</h2>
              </div>
              {/* Asian Brands */}
              {data ? data.map(renderCountryPackages) : null}
              {/* End Asian Brands */}
            </div>
          </div>
        </section>
      </div>
      {/* End Packages */}

      {/* How it's work  */}
      <How_its_work />
      {/* End How it's work */}

      {/* Car Brands We Work With */}
      <section className="certificate-section pt-4 pb-0 pt-sm-5">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>{getNameByTargetName("BRANDS_WE_WORK_ON_TITLE")}</h2>
            <div className="text">
              {getNameByTargetName("BRANDS_WE_WORK_ON_CONTENT")}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="et-icon-box-container column-8 et-make-container"
                data-gap="8"
              >
                {brands && brands.data
                  ? brands.data.map((brand, key) => {
                      return (
                        <div className="et-make et-icon-box" key={key}>
                          <a className="d-block">
                            <div className="each_brand_category">
                              <div className="icon_container">
                                <img
                                  className="img-fluid d-block mx-auto"
                                  alt="Nissan"
                                  srcSet={ASSETS + "carbrands/" + brand.image}
                                />
                              </div>
                              <div className="text_container">
                                <h3>Title</h3>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End Car Brands We Work With */}

      {/* WHY CHOOSE US */}
      <div className="mt-sm-5 mt-3">
        <section className="about-section-two pt-4 pb-0 pt-sm-5">
          <div className="auto-container">
            <div className="container clearfix mb-4">
              <div className="inner-column">
                <div className="no_after sec-title text-center">
                  <h2>{getNameByTargetName("WHY_CHOOSE_US_TITLE")}</h2>
                  <div className="text" style={{ fontSize: "15px" }}></div>
                </div>
                <div className="row">
                  <div className="row col-md-6">
                    <div className="feature-block-three m-4 ">
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/front/uploads/generalSection/magnifier.png"
                            style={{ height: "35px" }}
                          />
                        </div>
                        <h4>
                          <a href="#">
                            {getNameByTargetName("WHY_CHOOSE_US_S_TITLE_ONE")}
                          </a>
                        </h4>
                        <div className="text">
                          <p style={{ textAlign: "justify" }}>
                            {getNameByTargetName("WHY_CHOOSE_US_S_ONE_CONTENT")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Feature Block */}
                  <div className="row col-md-6">
                    <div className="feature-block-three m-4">
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/front/uploads/whychoose/value_Customer.png"
                            style={{ height: "35px" }}
                          />
                        </div>
                        <h4>
                          <a href="#">
                            {getNameByTargetName("WHY_CHOOSE_US_S_TITLE_TWO")}
                          </a>
                        </h4>
                        <div className="text">
                          <p style={{ textAlign: "justify" }}>
                            {getNameByTargetName("WHY_CHOOSE_US_S_TWO_CONTENT")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Feature Block */}

                  {/* Feature Block */}
                  <div className="row col-md-6">
                    <div className="feature-block-three m-4">
                      <div className="inner-box">
                        <div className="icon-box">
                          <div className="fa-2x fas fas fa-handshake"></div>
                        </div>
                        <h4>
                          <a href="#">
                            {getNameByTargetName("WHY_CHOOSE_US_S_TITLE_THREE")}
                          </a>
                        </h4>
                        <div className="text">
                          <p style={{ textAlign: "justify" }}>
                            {getNameByTargetName(
                              "WHY_CHOOSE_US_S_THREE_CONTENT"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Feature Block */}
                  <div className="row col-md-6">
                    <div className="feature-block-three m-4">
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/front/uploads/generalSection/Competitive.png"
                            style={{ height: "35px" }}
                          />
                        </div>
                        <h4>
                          <a href="#">
                            {getNameByTargetName("WHY_CHOOSE_US_S_TITLE_FOUR")}
                          </a>
                        </h4>
                        <div className="text">
                          <p style={{ textAlign: "justify" }}>
                            {getNameByTargetName(
                              "WHY_CHOOSE_US_S_FOUR_CONTENT"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Feature Block */}

                  {/* Feature Block */}
                  <div className="row col-md-6">
                    <div className="feature-block-three m-4 mr-3">
                      <div className="inner-box">
                        <div className="icon-box">
                          <img
                            src="assets/front/uploads/generalSection/Tailor_Made.png"
                            style={{ height: "35px" }}
                          />
                        </div>
                        <h4>
                          <a href="#">
                            {getNameByTargetName("WHY_CHOOSE_US_S_TITLE_FIVE")}
                          </a>
                        </h4>
                        <div className="text">
                          <p style={{ textAlign: "justify" }}>
                            {getNameByTargetName(
                              "WHY_CHOOSE_US_S_FIVE_CONTENT"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* End WHY CHOOSE US */}

      {/* Sevices*/}
      <Services />
      {/*End services  */}

      {/*Our Car Service Partners */}
      <section className="py-5">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>{getNameByTargetName("OUR_SERVICE_PARTNERS_TITLE")}</h2>
            <div className="text">
              {getNameByTargetName("OUR_SERVICE_PARTNERS_CONTENT")}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="our_partners_wrapper">
                <OwlCarousel
                  className="owl-theme"
                  autoplaySpeed={10}
                  items={5}
                  slideBy={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  rtlClass="owl-rtl"
                  rtl={true}
                >
                  {partners && partners.data
                    ? partners.data.map((partner, key) => {
                        return (
                          <div className="item one" key={key}>
                            <div className="each_item_wrapper">
                              <img
                                className="img-fluid"
                                alt="Partners"
                                src={ASSETS + "ourcarservices/" + partner.image}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Our Car Service Partners */}

      {/* our client */}
      <section className="py-5 bg_light_ourclients">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>{getNameByTargetName("OUR_CLIENTS_TITLE")}</h2>
            <div className="text">
              {getNameByTargetName("OUR_CLIENT_CONTENT")}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="our_partners_wrapper">
                <OwlCarousel
                  className="owl-theme"
                  items={5}
                  slideBy={1}
                  loop={true}
                  autoplay={true}
                  rtlClass="owl-rtl"
                  rtl={true}
                  dots={false}
                >
                  {clients && clients.data
                    ? clients.data.map((client, key) => {
                        return (
                          <div className="item one" key={key}>
                            <div className="each_item_wrapper">
                              <img
                                className="img-fluid"
                                alt="Clients"
                                src={ASSETS + "ourclients/" + client.image}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end our client */}

      {/* FAQ's Section */}
      <section className="faq-section pt-4 pt-sm-5 pb-0">
        <div className="auto-container">
          {/* Sec Title */}
          <div className="sec-title text-center mb-5">
            <h2>{getNameByTargetName("FAQS_TITLE")}</h2>
            <div className="text">{getNameByTargetName("FAQS_CONTENT")}</div>
          </div>
          <div className="row clearfix">
            {/* Content Column */}
            <div className="content-column col-xl-7 col-lg-6 col-md-12 col-sm-12 order-2">
              <div
                id="accordionExample"
                className="accordion shadow accordion-box"
              >
                {faqs &&
                  faqs.map((element, index) => (
                    <div className="card" key={index}>
                      <div
                        id={"headingOne" + index}
                        className="card-header shadow-sm border-0 acc-btn"
                      >
                        <h6 className="mb-0 font-weight-bold">
                          <a
                            href="#"
                            data-toggle="collapse"
                            data-target={"#collapseOne" + index}
                            // aria-expanded={index == 0 ? "true" : "false"}
                            aria-controls={"collapseOne" + index}
                            className={
                              index == 0
                                ? "d-block position-relative text-white collapsible-link py-2"
                                : "d-block position-relative text-white collapsed collapsible-link py-2"
                            }
                          >
                            {selectedLanguage == "en"
                              ? element[0].value
                              : element[0].value_ar}
                          </a>
                        </h6>
                      </div>
                      <div
                        id={"collapseOne" + index}
                        aria-labelledby={"headingOne" + index}
                        data-parent="#accordionExample"
                        className={index == 0 ? "collapse" : "collapse"}
                      >
                        <div className="card-body p-5">
                          <p className="font-weight-light m-0 content">
                            {selectedLanguage == "en"
                              ? element[1].value
                              : element[1].value_ar}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Image Column */}
            <div className="image-column col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <figure className="image">
                  <img
                    src="assets/front/uploads/faq/faq11.jpg"
                    alt="Bat-oman"
                  />
                </figure>
                <div className="caption-box mt-5">
                  <h4>
                    <a href="tel:+968 800 500 111" className="bold-text ">
                      {getNameByTargetName("TOLL_FREE")}
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End FAQ's Section */}

      {/* Testimonials */}
      <section className="py-5 bg_light mt-0 mt-md-5">
        <div className="auto-container">
          <div className="sec-title text-center mb-5">
            <h2>{getNameByTargetName("CUSTOMER_REVIEWS")}</h2>
            <div className="text"></div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="testimonials_container">
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  slideBy={1}
                  loop={true}
                  autoplay={true}
                  dots={false}
                  rtlClass="owl-rtl"
                  rtl={true}
                  {...options}
                >
                  <div className="item one">
                    <div className="each_testimonial text-center">
                      <div className="testimonial_head">
                        <div className="testimonial_header">
                          <div className="elementskit-watermark-icon ekit_watermark_icon_custom_position">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55.331"
                              height="39.522"
                              viewBox="0 0 55.331 39.522"
                            >
                              <path
                                id="quote_icon"
                                d="M11.452,50.022H23.309l7.9-15.809V10.5H7.5V34.213H19.357Zm31.618,0H54.926l7.9-15.809V10.5H39.118V34.213H50.974Z"
                                transform="translate(62.831 50.022) rotate(180)"
                                fill="#04367d"
                                opacity="0.2"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="testimonial_data">
                          <p>
                            We are very happy with Bahwan Automobiles & Trading
                            performance, they help and support us with
                            outstanding service. We wish to continue with them
                            for the future. Thanks and Best Regards.
                          </p>
                        </div>
                      </div>
                      <div className="testimonial_footer">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid"
                            src="assets/front/uploads/testimonials/one.png"
                          />
                        </div>
                        <div className="client_data">
                          <h3>Karum AL-Balushi</h3>
                          <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="far fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item two">
                    <div className="each_testimonial text-center">
                      <div className="testimonial_head">
                        <div className="testimonial_header">
                          <div className="elementskit-watermark-icon ekit_watermark_icon_custom_position">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55.331"
                              height="39.522"
                              viewBox="0 0 55.331 39.522"
                            >
                              <path
                                id="quote_icon"
                                d="M11.452,50.022H23.309l7.9-15.809V10.5H7.5V34.213H19.357Zm31.618,0H54.926l7.9-15.809V10.5H39.118V34.213H50.974Z"
                                transform="translate(62.831 50.022) rotate(180)"
                                fill="#04367d"
                                opacity="0.2"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="testimonial_data">
                          <p>
                            I have experienced excellent service. You guys are
                            the best ! keep up the great work.&#128077;
                          </p>
                          {/* Regards Ameen Khan
                            Asst.Managr-Service Ph- 94299319 Tel: 00968
                            -24032005 Fax: 00968-24032020 Email :
                            servicehead@orbit-oman.com */}
                        </div>
                      </div>
                      <div className="testimonial_footer">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid"
                            src="assets/front/uploads/testimonials/L3RtcC9waHBCOFNoR2k%3d.png"
                          />
                        </div>
                        <div className="client_data">
                          <h3>Ameen Khan</h3>
                          <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item three">
                    <div className="each_testimonial text-center">
                      <div className="testimonial_head">
                        <div className="testimonial_header">
                          <div className="elementskit-watermark-icon ekit_watermark_icon_custom_position">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55.331"
                              height="39.522"
                              viewBox="0 0 55.331 39.522"
                            >
                              <path
                                id="quote_icon"
                                d="M11.452,50.022H23.309l7.9-15.809V10.5H7.5V34.213H19.357Zm31.618,0H54.926l7.9-15.809V10.5H39.118V34.213H50.974Z"
                                transform="translate(62.831 50.022) rotate(180)"
                                fill="#04367d"
                                opacity="0.2"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="testimonial_data">
                          <p>
                            Greetings from Al Maha Rent A Car! It is indeed a
                            great pleasure to comment on the efficiency and
                            commitment, your team has been successful in
                            maintaining the quality of the service and committed
                            our timelines. We sincerely look forward to a very
                            healthy partnership in the years to come. Thank you
                            for all your support. With regards
                          </p>
                        </div>
                      </div>
                      <div className="testimonial_footer">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid"
                            src="assets/front/uploads/testimonials/L3RtcC9waHBQZzNZeFM%3d.png"
                          />
                        </div>
                        <div className="client_data">
                          <h3>
                            Gijo George Mampilly
                            <br />
                            Asst. General Manager
                            <br />
                            Al Maha Rent a Car
                          </h3>

                          <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item four">
                    <div className="each_testimonial text-center">
                      <div className="testimonial_head">
                        <div className="testimonial_header">
                          <div className="elementskit-watermark-icon ekit_watermark_icon_custom_position">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="55.331"
                              height="39.522"
                              viewBox="0 0 55.331 39.522"
                            >
                              <path
                                id="quote_icon"
                                d="M11.452,50.022H23.309l7.9-15.809V10.5H7.5V34.213H19.357Zm31.618,0H54.926l7.9-15.809V10.5H39.118V34.213H50.974Z"
                                transform="translate(62.831 50.022) rotate(180)"
                                fill="#04367d"
                                opacity="0.2"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="testimonial_data">
                          <p>Professionalism, Punctuality, Quality, Value</p>
                        </div>
                      </div>
                      <div className="testimonial_footer">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid"
                            src="assets/front/uploads/testimonials/L3RtcC9waHBkcEw0NzI%3d.png"
                          />
                        </div>
                        <div className="client_data">
                          <h3>Mohammed Sabeelur Rehman</h3>
                          <div>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Testimonial */}
    </>
  );
}
