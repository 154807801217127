import React from "react";
import { seo_data } from "../../SEO";
import { Helmet } from "react-helmet";
import { useLanguage } from "../LanguageContext";
import { useSelector } from "react-redux";

export default function How_its_work() {

  const { selectedLanguage } = useLanguage();

  const homeData = useSelector((state) => state.staticData.data) || [];


  const getNameByTargetName = (targetName) => {
    const targetObject = homeData.find(item => item.name === targetName);
   if(selectedLanguage=="en"){
     return targetObject ? targetObject.value : null;
   }else{
     return targetObject ? targetObject.value_ar : null;
   }
}



  return (
    <> <Helmet>
  
  </Helmet>

    <div className="mt-4">
      <section className="how_it_works_main_container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 max1200 min1200 d-block mx-auto">
              <div className="sec-title text-center mb-5">
                <h2>{getNameByTargetName('HOW_ITS_WORK_TITLE')}</h2>
                <div className="text">
                {getNameByTargetName('HOW_ITS_WORK_CONTENT')}
                </div>
                {/* <span className="devider">
                <span></span>
              </span> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="medium-text bold-text text-center">
                  {getNameByTargetName('BAT_INSPECTION')}
                  </h3>
                  <div className="how_it_works_wrapper mt-4">
                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/1.png"
                          />
                        </div>
                        <div className="text_container_mobile text-center">
                          <h3 className="small-text">
                          {getNameByTargetName('HOW_ITS_WORK_ONE')}
                          </h3>
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">1</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_ONE')}
                        </h3>
                      </div>
                    </div>
                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/2.png"
                          />
                        </div>
                        <div className="text_container_mobile text-center">
                          <h3 className="small-text"></h3>
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">2</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_TWO')}
                        </h3>
                      </div>
                    </div>
                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/3.png"
                          />
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">3</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_THREE')}
                        </h3>
                      </div>
                    </div>
                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/4.png"
                          />
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">4</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_FOUR')}
                        </h3>
                      </div>
                    </div>
                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/5.png"
                          />
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">5</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_FIVE')}
                        </h3>
                      </div>
                    </div>

                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/6.png"
                          />
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">6</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_SIX')}
                        </h3>
                      </div>
                    </div>

                    <div className="each_how_it_works_design">
                      <div className="movile_view_wrap">
                        <div className="image_wrapper">
                          <img
                            className="img-fluid d-block mx-auto"
                            src="/assets/front/how_it_work/7.png"
                          />
                        </div>
                      </div>
                      <div className="arrow_container text-center">
                        <span className="point">7</span>
                      </div>
                      <div className="text_container text-center">
                        <h3 className="small-text">
                        {getNameByTargetName('HOW_ITS_WORK_SEVEN')}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}
